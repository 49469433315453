export const patientIRD: any = {
  mainMenu: "Main Navigational Menu Consumer Retina Brazil (PT)",
  footerMenu: "Footer Navigation Menu Consumer Retina Brazil (PT)",
  banner: "Banner - HTML - IRD - DTC - Brazil (PT)",
  carousel: "",
  footer: "Footer - Consumer - Retina Brazil (PT)",
  exitPopup: "Exit Popup Brazil (PT)",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
  topNav: "Top Navigation Menu Retina Brazil (PT)",
  siteLogo: "Site logos - Retina Brazil (PT)",
  socialBlock: "Footer Social Icons",
  footerRef: "DTC - Inherited Retinal Disease - Reference - Brazil (PT)",
  video1: "How do inherited retinal diseases happen - Inherited Retinal Disease - Retina Brazil (PT)",
  video2: "How are retinal diseases inherited - Inherited Retinal Disease - Retina Brazil (PT)",
  infographic: "Infographic Section - Inherited Retinal Disease - Retina Brazil (PT)",
  footerCallout: "Navigational Callout - Inherited Retinal Disease - Brazil (PT)",
  ifYouDoSection: "If You Do - Inherited Retinal Disease - Retina Brazil (PT)",
  hardToTell: "It's Hard Tell - Inherited Retinal Disease - Retina Brazil (PT)",
  accordionSection: "Accordion Section - Inherited Retinal Disease New - Retina Brazil (PT)",
  irdDefines: "Inherited Retinal Disease Defines - Inherited Retinal Disease - Retina Brazil (PT)",
  familyHistory: "Family history alone - Inherited Retinal Disease - Retina Brazil (PT)",
  calloutSection: "Callout Section - Inherited Retinal Disease - Brazil (PT)",
  whatAreGenes: "What Are Genes - Inherited Retinal Disease - Brazil (PT)",
  slider: "Slider - Inherited retinal disease - Brazil (PT)",
  closerLook: "Let’s take a closer look - Inherited Retinal Disease - Brazil (PT)",
  backToTopGTM: "back to top - inherited retinal diseases",
  video1Attrs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "genes 101 - view transcript",
        "className": "gtm-accordion",
        "aria-label": "Mostrar transcrição para Princípios de Genética"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "genes 101 - hide transcript",
        "className": "gtm-accordion",
        "aria-label": "Mostrar transcrição para Princípios de Genética"
      }
    ]
  },
  video2Attrs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "inheritance 101 - view transcript",
        "className": "gtm-accordion",
        "aria-label": "Mostrar transcrição para Hereditariedade Genética"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "inheritance 101 - hide transcript",
        "className": "gtm-accordion",
        "aria-label": "Mostrar transcrição para Hereditariedade Genética"
      }
    ]
  },
  sliderAttrs: {
    bulletGTMLabels: [
      'inside the eye graphic - step 1',
      'inside the eye graphic - step 2',
      'inside the eye graphic - step 3'
    ],
    bulletAriaLabels: [
      'Ícone 1',
      'Ícone 2',
      'Ícone 3'
    ],
    prevBtnGTMLabel: {
      'data-gtm-event-label': 'inside the eye graphic - previous',
      'className': 'gtm-link-internal',
      'aria-label': 'Previous slide'
    },
    nextBtnGTMLabel: {
      'data-gtm-event-label': 'inside the eye graphic - next',
      'className': 'gtm-link-internal',
      'aria-label': 'Next slide'
    }
  },
  footerClassName: "inherited-retinal-diseases-footer"
}
